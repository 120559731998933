<template>
<div class="form_radio_group">
	<div :style="`width: ${100/items.length-0.1}%`" class="form_radio_group-item" v-for="(el, i) in items" :key=i>
		<input :id='"radio-"+uid+"-"+i' type="radio" :checked="value==el.value ? 'checked' : null" :name='"radio-"+ uid' :value=el.value @change=newValue>
		<label :for='"radio-"+uid+"-"+i'><span v-html="el.text"></span></label>
	</div>
</div>
</template>

<script>

export default {
  name: "Chooser",

  props: {
      items: {
          type: Array,
          default: [],
      },
      value: {
        type: [String, Number],
      }, 
    },
        
    methods: {
      newValue(event)
      {
        this.$emit("input", event.target.value)
      }
  },    

    // данные компонента, делаются в виде функции, чтобы у каждой копии компонента был свой набор данных
    // иначе данные будут одни на всех
    data()
    {
        return {

        }
    },

    // еще тут могут быть разделы components, props, mounted, created, computed но пока они не нужны
}
</script>

<style scoped>
    .form_radio_group {
	display: inline-block;
	overflow: hidden;
    height: 100%;
    width: 100%;
    margin-bottom: 5px;
    padding-right: 1px;
    padding-bottom: 1px;
}
.form_radio_group-item {
	display: inline-block;
	float: left;
    height: 100%;
}
.form_radio_group input[type=radio] {
	display: none;
}
.form_radio_group label {
	cursor: pointer;
	line-height: 34px;
	border: 1px solid #999;
	border-right: none;
	user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

}

@media screen and (max-width: 540px) {
    label{
    font-size: 16px;
    width: 100%;
    height: 96%;
    font-size: 10px
}
}
@media screen and (min-width: 540px) {
    label{
    font-size: 16px;
    width: 100%;
    height: 96%;

}
}

.internet-speed{
    font-weight: 600;
}

.form_radio_group .form_radio_group-item:first-child label {
	border-radius: 6px 0 0 6px;
}
.form_radio_group .form_radio_group-item:last-child label {
	border-radius: 0 6px 6px 0;
	border-right: 1px solid #999;
}

/* Checked */
.form_radio_group input[type=radio]:checked + label {
	background: rgb(199,33,39);
    color: white;
}

/* Hover */
.form_radio_group label:hover {
	color: #666;
}

</style>