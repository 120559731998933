import Vue from 'vue'

export async function postTask(form)
{
        try {
                const response = await fetch(`${process.env.VUE_APP_SERVER}/api/task`,
                {
                  method: 'POST',
                  body: JSON.stringify(form),
                  headers: {"Content-Type": "application/json"},
                })

            if (response.ok)
            {
              Vue.notify(
              {
                group: 'foo',
                type: 'success',
                title: 'Вітаю',
                text: "Ваш запит відправлено",
              })
            }
            else
            {
              const err = await response.json();
              Vue.notify(
              {
                group: 'foo',
                type: 'error',
                title: 'Помилка, зверніться до адміністратора',
                text: err.humanText || `${response.url}<br/>${response.status} ${response.statusText}`,
              });
            }
        }
        catch(error) {
            Vue.notify(
            {
              group: 'foo',
              type: 'error',
              title: 'Помилка, зверніться до адміністратора',
              text: error.jsonBody?.error || error
            });
        }
}
