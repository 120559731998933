<template>
<div class="uk-flex uk-flex-column tv-page">
    <div class="uk-flex uk-flex-row tv-menu">
        <div class="uk-flex uk-flex-row trinity"><router-link class="router-link-tv-menu" to="/tv/trinity">Trinity TV</router-link></div>
        <div class="uk-flex uk-flex-row omega"><router-link class="router-link-tv-menu" to="/tv/omega">Omega TV</router-link></div> 
    </div>

    <router-view />
    
</div>
</template>

<script>
export default {
  name: "TVPage",

};
</script>

<style >
.tv-menu{
    width: 100%;
    justify-content: center;
}

@media screen and (min-width: 1200px) {
.tv-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 30px;   
}
}

@media screen and (max-width: 1200px) {
    .tv-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 30px;   
}    
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
    .tv-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 15%;   
}    
}

@media screen and (max-width: 540px) {
    .tv-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 25%;   
}
}



.trinity{
    font-size: 20px;
    
}

.router-link-active{
    color:rgb(199,33,39) !important;
}

.router-link-tv-menu{
    color: black;
}

.router-link-tv-menu:hover{
    color:rgb(199,33,39);
    outline: none;
    text-decoration: none;
}

.router-link-tarif-menu{
    color: black;
}

.router-link-tarif-menu:hover{
    color:rgb(199,33,39);
    outline: none;
    text-decoration: none;
}

.omega{
    margin-left: 5%;
    font-size: 20px;
}

</style>