<template>
<div uk-modal class="uk-modal" :class="{'uk-open': opened}" :style="{display: opened ? 'block' : 'none'}">
  <div class="uk-modal-dialog uk-modal-body modal-question">
     <h2 class="uk-modal-title">Вкажіть інформацію для з'єднання</h2>

     <div>
       <div class="uk-margin">
         <div class="uk-inline calc-form-item">
           <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: user"></span>
           <input v-model=fio class="uk-input calc-input" type="text" placeholder="Повне ім'я">
         </div>
       </div>

       <div class="uk-margin">
         <div class="uk-inline calc-form-item">
            <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: receiver"></span>
            <input v-model=phone class="uk-input calc-input" type="text" placeholder="Номер телефону">
         </div>
       </div>

       <div class="uk-margin">
         <div class="uk-inline calc-form-item">
            <span  style ="z-index:100" class="uk-form-icon calc-form-icon-item" uk-icon="icon: location"></span>
            <TownSelect v-model=town class="uk-input calc-input" />
         </div>
       </div>

     </div>
     <p class="uk-text-right">
        <button @click=closeModal class="uk-button uk-button-default question-close-button" type="button">Вийти</button>
        <button @click=postTask class="uk-button uk-button-primary question-submit-button" type="button">Відправити</button>
     </p>
  </div>
</div>
</template>

<script>
import {postTask} from "@/common.js"
import TownSelect from "@/components/TownSelect.vue"

export default {

  name: "TaskModal",
  
  components: {TownSelect},
  
  props: {
    title: {
      type: String,
      default: "Підключення інтернету",
    },
  },
  
  data(){
    return {
      opened: false,
      description: "",
      phone: "",
      fio: "",
      town: "",
    }
  },
  
  created()
  {
    this.$on("open", descr => 
    {
      this.description = descr
      this.opened = true
    })
  },
  
  methods: {
    closeModal()
    {
      this.opened = false
    },
    
    async postTask()
    {
      if (!this.fio || !this.phone || !this.town)
      {
        this.$notify({
          group: 'foo',
          type: 'warn',
          title: 'Помилка',
          text: 'Введіть потрібні дані',
        })
        return
      }
    
      const formData = {
        type: 'new-connection',
        title: this.title,
        description: this.description,
        task_attr: {
          fio: this.fio,
          phone: this.phone,
          address: this.town,
        },
      }
        
      await postTask(formData)
      this.closeModal()
    },
  },
  
}
</script>

<style scoped>
.uk-input:not(input), .uk-select:not(select) {
    line-height: 20px;
}
</style>


