<template>

<div class="uk-flex uk-flex-row qwe">
  <div class="news-block">
    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
        <div class="uk-card-media-left uk-cover-container icon-container">
            <img class="news-item-icon" :src=info.img alt="" uk-cover>
        </div>
            <div class="text-container"  :class="{'noimg-container': !info.img}">
        <div class="uk-card-body">
          <div class="uk-flex uk-flex-row">
            <div class="uk-card-title news-title">{{info.title}}</div>
          </div>
            <div class="news-teaser">{{info.content}}</div>
            <div class="uk-flex news-date"><span class="uk-badge news-date-item">{{newsCreateTime}}</span></div>
        </div>
    </div>
  </div>  
</div>
</div>

</template>

<script>
import moment from 'moment';

export default {
    name: "FullNews",

    props: {
        id: Number,
    },
        
    // данные компонента, делаются в виде функции, чтобы у каждой копии компонента был свой набор данных
    // иначе данные будут одни на всех
    data()
    {
        return {
           info: {},
        }
    },

    computed: {
      newsCreateTime()
      {
        return moment.unix(this.info.create_date).format('DD.MM.YYYY')
      }
  },

    created()
    {
      this.loadNews(this.$route.params.id)
    },

    // еще тут могут быть разделы components, props, mounted, created, computed но пока они не нужны
    methods: {
        async loadNews(id)
        {
            const resp = await fetch(`${process.env.VUE_APP_SERVER}/api/news/${id}`)
            this.info = await resp.json()
        }
    }
}

</script>

<style scoped>

.news-block{ 
    width: 80%;
    margin-top: 5%;
    margin-left: 10%;
}


.news-content {
    font-size: 15px;
}

.news-item-icon{
    Object-fit: scale-down;
    width: 100% !important;
    height: 100% !important;
    padding: 10px;
}

.icon-container, .news-date{
    width: 25%;
}

.noimg-container{
    width: 100% !important;
}

.uk-badge {
    min-width: unset;
}

.text-container, .news-title{
    width: 75%;
}

.news-date-item{
    margin-bottom: 20px;
}


.uk-card-title {   
    font-size: 20px;
    font-weight: bold;
 }
 
 
.news-date-item{
    
    background: rgb(199,33,39);
}

@media screen (min-width: 540px) { 
    .news-block{
    width: 80%;
    margin-top: 15%;
    margin-left: 10%;
}
}

@media screen and (max-width: 540px) { 
    .news-block{
    margin-top: 25%;
    width: 80%;
    margin-left: 10%;
}
}

@media screen and (max-width: 540px) and (orientation: landscape) { 
    .news-block{
    margin-top: 25%;
    width: 80%;
    margin-left: 10%;
}
}

@media screen and (max-width: 1200px) { 
    .news-block{
    margin-top: 30%;
    width: 80%;
    margin-left: 10%;
}
}

@media screen and (max-width: 1200px) and (orientation: landscape) { 
    .news-block{
    margin-top: 20%;
    width: 80%;
    margin-left: 10%;
}
}
</style>