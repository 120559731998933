import Vue from 'vue';
import Notifications from 'vue-notification';
import App from './App.vue';
import VueRouter from 'vue-router';
import routes from "./routes";
import UniqueId from 'vue-unique-id';
import store from "./store.js"
import {logoutWhenExpired} from "@/login.js"

Vue.use(UniqueId);
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.config.productionTip = false;

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path.match("^/admin/") && !store.state.token) next({ path: '/login' })
  else next()
})

new Vue({
  router,
  store,
  render(h) { return h(App); },
  created: function()
  {
    logoutWhenExpired();
  },
}).$mount('#app');

store.dispatch("loadTariffs")
