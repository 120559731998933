g<template>
<div class="uk-flex uk-flex-row tv-trinity">
    
    <TaskModal ref="modal" title="Підключення ТБ" />
    
    <div class="uk-flex uk-flex-column internet-flat-direct-tarif">
      <div class="internet-flat-direct-tarif-name">
        {{$store.state.tariffs.tv.TrinityTV.minimal.humanText}}
      </div>
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Кількість каналів
          </div>
          <div>
            {{$store.state.tariffs.tv.TrinityTV.minimal.channels}}
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-flat-direct-tarif-price">
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-block-cost">
        <div class="uk-flex internet-flat-direct-tarif-cost">
          {{$store.state.tariffs.tv.TrinityTV.minimal.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-flat-direct-tarif-length">
          <div class="internet-flat-direct-tarif-currency">
            грн
          </div>
          <div class="internet-flat-direct-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-confirm">
         <button @click="chooseTrinityTVminimal" class="tv-TrinityTV-but tv-TrinityTV-minimal-tarif-button">Підключити</button>
      </div>
      </div>
    </div>
    <div class="uk-flex uk-flex-column internet-flat-direct-tarif">
      <div class="internet-flat-direct-tarif-name">
        {{$store.state.tariffs.tv.TrinityTV.medium.humanText}}
      </div>
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Кількість каналів
          </div>
          <div>
            {{$store.state.tariffs.tv.TrinityTV.medium.channels}}
          </div>
        </div>
      </div>
      
      <div class="uk-flex uk-flex-column internet-flat-direct-tarif-price">
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-block-cost">
        <div class="uk-flex internet-flat-direct-tarif-cost">
         {{$store.state.tariffs.tv.TrinityTV.medium.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-flat-direct-tarif-length">
          <div class="internet-flat-direct-tarif-currency">
            грн
          </div>
          <div class="internet-flat-direct-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-confirm">
         <button @click="chooseTrinityTVmedium" class="tv-TrinityTV-but tv-TrinityTV-medium-tarif-button">Підключити</button>
      </div>
      </div>
    </div>
    
    <div class="uk-flex uk-flex-column internet-flat-direct-tarif">
      <div class="internet-flat-direct-tarif-name">
        {{$store.state.tariffs.tv.TrinityTV.maximal.humanText}}
      </div>
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Кількість каналів
          </div>
          <div>
            {{$store.state.tariffs.tv.TrinityTV.maximal.channels}}
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-flat-direct-tarif-price">
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-block-cost">
        <div class="uk-flex internet-flat-direct-tarif-cost">
          {{$store.state.tariffs.tv.TrinityTV.maximal.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-flat-direct-tarif-length">
          <div class="internet-flat-direct-tarif-currency">
            грн
          </div>
          <div class="internet-flat-direct-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-confirm">
         <button @click="chooseTrinityTVmaximal" class="tv-TrinityTV-but tv-TrinityTV-maximal-tarif-button">Підключити</button>
      </div>
      </div>
    </div>
</div>
</template>

<script>

import store from '@/store'
import TaskModal from "@/components/TaskModal.vue"

export default {
  name: "Omega",
  components: {TaskModal},
  data(){
    return {
      
    }
  },
  methods: {
       chooseTrinityTVminimal()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.$store.state.tariffs.tv.TrinityTV.minimal.humanText} Цена ${this.$store.state.tariffs.tv.TrinityTV.minimal.price}`)
       },
       chooseTrinityTVmedium()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.$store.state.tariffs.tv.TrinityTV.medium.humanText} Цена ${this.$store.state.tariffs.tv.TrinityTV.medium.price}`)
       },
       chooseTrinityTVmaximal()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.$store.state.tariffs.tv.TrinityTV.maximal.humanText} Цена ${this.$store.state.tariffs.tv.TrinityTV.maximal.price}`)
       },
      },
      computed: {
      tariffs()
      {
        return this.$store.state.tariffs.tv.TrinityTV
      }
    },

  beforeRouteEnter: async (to, from, next) =>
 {
   if ('tv' in store.state.tariffs) return next()
   await store.dispatch("loadTariffs")
   next()
 },

};
</script>

<style scoped>

    @media screen and (min-width: 1000px) {
    .tv-trinity{
      gap: 20px;
      justify-content: center;
      margin-top: 15px;
      display: flex;
      flex-direction: row;
            align-items: center;
    }
    .internet-flat-direct-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 25%;
    }
}
    @media screen and (max-width: 540px) {
    .tv-trinity{
      gap: 20px;
      justify-content: center;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .internet-flat-direct-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 80%;
    }
}

@media screen and ( min-width: 540px) and ( max-width: 1000px) {
      .tv-trinity{
      gap: 20px;
      justify-content: center;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .internet-flat-direct-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 50%;
    }
}


    

    

    .internet-flat-direct-tarif-price{
      justify-content: flex-end;
      height: 100%;
    }

    .internet-flat-direct-tarif-block-cost{
      justify-content: center;
    }

    .internet-flat-direct-tarif-name{
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 25px;
      text-align: center;
      font-weight: 600;
      color: black;
    }

    .internet-flat-direct-tarif-item{
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .internet-flat-direct-tarif-description{
      margin-left: 20px;
      letter-spacing: 1px;
    }

    .internet-flat-direct-tarif-item-icon{
      color: rgb(199,33,39);

    }

    .internet-flat-direct-tarif-cost{
      font-size: 50px;
      color: rgb(146,27,30);
    }

    .internet-flat-direct-tarif-length{
      justify-content: center;
      color: rgb(146,27,30);
      margin-left: 10px;
    }

    .internet-flat-direct-tarif-currency{
      margin-top: 5px;
      margin-bottom: -5px;
    }

    .internet-flat-direct-tarif-duration{
      margin-bottom: 5px;
    }

    .internet-flat-direct-tarif-confirm{
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
    }

    .tv-TrinityTV-but{
      padding: 10px;
      width: 70%;
      border-radius: 5px;
      outline: none;
      border: 1px solid lightgrey;
    }

    .tv-TrinityTV-but{
  -webkit-transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
}
.tv-TrinityTV-but:hover {
  box-shadow: 0 0 40px 40px rgb(146,27,30) inset;
  border: 1px solid grey;
  color: white;
}
</style>