<template>
    <div class="uk-flex uk-flex-column">
        <div>Title</div>
        <input v-model=model.title type="text">
        <div v-show="error.title">error.title</div>
        <div>Teaser</div>
        <input v-model=model.teaser type="text">
        <div v-show="error.title">error.title</div>
        <div>Content</div>
        <textarea rows=5 v-model=model.content />
        <div>img</div>
        <img :src=model.img >
        <input type="file">
        <button @click=changeNews> Изменить </button>
    </div>
</template>

<script>

import http from "@/http.js"

export default {



data() {
     return {
       
        model: {},
        error: {},
      
    }
    
  },

  created()
    {
      this.loadNews(this.$route.params.id)
    },

  methods: 
    {
      async loadNews(id)
        {
            this.model = await http.get(`${process.env.VUE_APP_SERVER}/api/news/${id}`, {notify: true})
        },
        
        async changeNews()
        {
          const input = this.$el.querySelector('input[type=file]'),
          file = input.files[0];
          if (file && file.type.match(/image.*/))
          {
            this.model.img = file
          }
          try
          {
            const response = await http.patch(`${process.env.VUE_APP_SERVER}/admin/news/${this.$route.params.id}`,
            this.model,)              
              this.model = response
              this.$router.push({path: "/admin/news"})

              this.$notify(
              {
                group: 'foo',
                type: 'success',
                title: 'Вітаємо!',
                text: "Новина успішно змінена",
              });
          }
          catch(error)
          {
            this.$notify(
            {
              group: 'foo',
              type: 'error',
              title: 'Ошибка, обратитесь к администратору',
              text: `${error}`
            });
          }
        },
        
    }
}


</script>

<style scoped>

</style>