<template>
    <div>
        <h3>Телебачення</h3>
        <div v-for="(key, i) in tvKeys" :key=key>
            {{key}} : <input type="text" v-model="model[key]">
            <button @click=update(key)>КЛАЦ</button>
        </div>

        <h3>Інтернет</h3>
        <div v-for="(key, i) in internetKeys" :key=key>
            {{key}} : <input type="text" :name=key v-model="model[key]">
            <button @click=update(key)>КЛАЦ</button>
        </div>

        <h3>Додатково</h3>
        <div v-for="(key, i) in additionalKeys" :key=key>
            {{key}} : <input type="text" :name=key v-model="model[key]">
           <button @click=update(key)>КЛАЦ</button>
        </div>

    </div>
</template>

<script>

import http from "@/http.js"

export default {
    name: "AdminTariffPage",
    
    data()
    {
        return {
            model: {},
        }
    },

    methods: {
       flatTree(prefix, subtree)
       {
         let list = {}
         if (!subtree) return list
         
         if ("humanText" in subtree)
         {
             for (const key in subtree)
             {
               if (key=="humanText") continue
               list[`${prefix}.${key}`] = subtree[key]
             }
            
         }
         else
         {
           for (const key in subtree)
           {
              const newList = this.flatTree(`${prefix}.${key}`, subtree[key])
              list = {...newList, ...list}
           }
         }
         
         return list
       },
       
       async update(path)
       {
        const formdata = new FormData()
        const reqPath = path.replace(/^\./, '')
        formdata.append("value", this.model[path])
        await http.patch(`${process.env.VUE_APP_SERVER}/admin/meta/tariff/${reqPath}`, {value: this.model[path]}, {notify: true})
       }

    },

    computed: {
      tvKeys() 
      {
        return Object.keys(this.model).filter(el => el.match(/^\.tv/)).sort()
      },
      internetKeys() 
      {
        return Object.keys(this.model).filter(el => el.match(/^\.internet/)).sort()
      },
      additionalKeys() 
      {
        return Object.keys(this.model).filter(el => el.match(/^\.additional/)).sort()
      },
    },

    async created()
    {
        const resp = await fetch(`${process.env.VUE_APP_SERVER}/api/meta/tariff`)
        const response = await resp.json()
        this.model = this.flatTree("", response)
    }
    
}

</script>

<style scoped>


</style>