<template>
  <div>
     <NewsItem v-for="el in items" :news=el :key=el.id />
  </div>
</template>

<script>
import NewsItem from "@/components/news/NewsItem.vue"

export default {
    name: "NewsRibbon",
    components: { NewsItem },

    props: {
        items: {
            type: Array,
        }
    },

    data()
    {
        return {

        }
    },
}

</script>

<style scoped>


</style>