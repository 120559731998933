<template>
<div class="uk-flex uk-flex-column news-page">
    <div class="uk-flex uk-flex-row add-news">
        <div class="back-link" @click=moveBack><span uk-icon="icon: arrow-left; ratio: 1.5"></span><router-link class="" to="/admin/menu">
        Назад
        </router-link> </div>
        <router-link class="router-link-internet-menu" to="/admin/addnews">
        Додати новину
        </router-link>
    </div>
    <div class="news-block">
    <NewsItem v-for="el in newsItems" :news=el :key=el.id @delete=deleteItem />
    </div>
</div>
</template>

<script>
import NewsItem from "@/pages/Admin/NewsItem.vue"
import http from "@/http.js"

export default {
    name: "AdminNewsPage",
    components: {NewsItem},
    data()
    {
        return {
            newsItems: [],
        }
    },

    methods: {
        moveBack(){
            this.$router.push('/admin/menu')
        },
        async deleteItem(id)
        {
            if (!confirm("Вы действительно хотите удалить эту новость?")) return
        try{
           await http.delete( `${process.env.VUE_APP_SERVER}/admin/news/${id}`)
              const i = this.newsItems.findIndex( el => el.id==id )
              if (i != -1)
              {
                this.newsItems.splice(i, 1)
              }
        }
        catch(error){
            this.$notify(
            {
              group: 'foo',
              type: 'error',
              title: 'Помилка, зверніться до адміністратора',
              text: `${error.message}`
            });
        }
            
        }
    },

    async created()
    {
        const resp = await http.get(`${process.env.VUE_APP_SERVER}/api/news`, {notify: true})
        this.newsItems = resp.data
    }
    
}

</script>

<style scoped>

.add-news{
    margin-top: 20px;
    font-size: 20px;
    justify-content: center;
}

.back-link{
    position: relative;
    left: -30%;
    display: flex;
    align-items: center;
}

.news-page{
    justify-content: space-between;
}

.news-block{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
}

.ads-block{
    margin-top: 2em;
    margin-right: 10%;
    width: 20%;
}


</style>