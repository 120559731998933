import Vue from 'vue'
import store from "@/store.js"


function escapeHTML(unsafe)
{
  return unsafe.replace(/[&<"']/g, function(m)  // "
  {
    switch (m) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '"':
       return '&quot;';
      default:
        return '&#039;';
    }
  });
}

const server = {
  
  get: function(url, options={})
  {
    return this._query("GET", url, options);
  }, 
  
  post: function(url, params, options={})
  {
    options.body = this._makeParams(params);
    return this._query("POST", url, options);
  },

  put: function(url, params, options={})
  {
    options.body = this._makeParams(params);
    return this._query("PUT", url, options);
  },
  
  patch: function(url, params, options={})
  {
    options.body = this._makeParams(params);
    return this._query("PATCH", url, options);
  },

  delete: function(url, params={}, options={})
  {
    options.body = this._makeParams(params);
    return this._query("DELETE", url, options);
  },
  
  getItems: function(url)
  {
    return this.get(url).catch(err => { this.notifyError(err); return [] });
  },

  _query: async function(method, url, options={})
  {
    const fetchOptions = options;
    fetchOptions.method = method;

    if (options.body) fetchOptions.body = options.body;

    const token = await store.dispatch('getToken')
    fetchOptions.headers = new Headers({"Authorization": `granat ${token}`})

    try {
      const response = await fetch(url, fetchOptions)
      if (!response.ok)
      {
        throw response;
      }
      const ctype = response.headers.get("Content-Type");
      if (ctype && (ctype.match(/^application\/json/) || ctype.match(/^application\/error\+json/)))
      {
        response.isJson = true;
      }
      return response.isJson ? await response.json() : await response.text();
    }
    catch(err)
    {
      const ctype = err.headers.get("Content-Type");
      if (ctype && (ctype.match(/^application\/json/) || ctype.match(/^application\/error\+json/)))
      {
        err.isJson = true;
      }
      const errorMsg = await this._describeError(err)

      if (options.notify) {
        this.notifyError(errorMsg)
      }
      else
      {
        throw errorMsg
      }
    }
  },

  _makeParams: function(params)
  {
    const f = new FormData;
    Object.keys(params).forEach( (k,i) => f.set(k, params[k]) );
    return f;
  },

  _describeError: async function(err)
  {

    if (err instanceof Response)
    {
      if (err.isJson)
      {
        const json = await err.json();
        err.jsonBody = json;
        err.message = json.humanText;
      }
      else
      {
        err.message = await err.text();
      }
    }
    else if (err instanceof Error)
    {
    }
    else
    {
      err = {message: err};
    }
    
    return err;    
  },
  
  notifyError: function(err)
  {
    if ("url" in err)
    {
      Vue.notify({ 
        group: 'foo',
        type: "error", 
        title: "Ошибка доступа к данным "+err.url, text: err.status + " " + err.statusText + ": " + escapeHTML(err.jsonBody.humanText),
      })
    }
    else
    {
      Vue.notify({group: 'foo', type: "error", title: "Ошибка доступа к данным", text: escapeHTML(err.message) });
    }
  },

};

export default server
