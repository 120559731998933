<template>
<div :uk-icon=currentIcon @click.stop=newValue />
</template>

<script>

export default {
  name: "PlusMinus",

  props: {

      value: {
        type: Boolean,
        default: true,
      },
      plusIcon: {
        type: String,
        default: "plus",
      },
      minusIcon: {
        type: String,
        default: "minus",
      },
    },

    // данные компонента, делаются в виде функции, чтобы у каждой копии компонента был свой набор данных
    // иначе данные будут одни на всех
    data()
    {
        return {
          localValue: this.value,
        }
    },

    methods: {
      newValue()
      {
          this.localValue = !this.localValue
          this.$emit("input", this.localValue)
      }
  },
  
  computed: {
      currentIcon()
      {
        return 'icon:' + (this.localValue ? this.plusIcon : this.minusIcon)+ '; ratio: 2.5'
      }
  },

  watch: {
      value(val)
      {   
          this.localValue = val
      },
  }



    // еще тут могут быть разделы components, props, mounted, created, computed но пока они не нужны
}
</script>

<style scoped>
   
</style>