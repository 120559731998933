<template>
<div
      class="uk-position-relative uk-visible-toggle slider"
      tabindex="-1"
      uk-slideshow="ratio: 1215:477; animation: push"
    >
      <ul class="uk-slideshow-items">
        <li v-for="(el, i) in items" :key=i>
          <img :src=el.img alt="" uk-cover />
          <div class="uk-position-center uk-position-small uk-text-center uk-light">
            <h2 class="uk-margin-remove">{{el.title}}</h2>
            <p class="uk-margin-remove" v-html="el.subtitle"></p>
          </div>
        </li>
      </ul>

      <div class="uk-light">
        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-previous
          uk-slideshow-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover"
          href="#"
          uk-slidenav-next
          uk-slideshow-item="next"
        ></a>
      </div>
    </div>
    
</template>

<script>
// компонент вью - это обычный хэш со специальными поллями
// который экспортируется из модуля по умолчанию
export default {
    name: "Slider", // должно быть уникальным

    props: {
      items: {
          type: Array,
          default: [],
      }, 
    },
        
    // данные компонента, делаются в виде функции, чтобы у каждой копии компонента был свой набор данных
    // иначе данные будут одни на всех
    data()
    {
        return {

        }
    },

    // еще тут могут быть разделы components, props, mounted, created, computed но пока они не нужны
}
</script>

<style scoped>

.slider {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  height: 60vh;
}

.uk-slideshow-items {
  border-radius: 20px;
  min-height: inherit !important;
  height: inherit !important;
}

@media screen and (min-width: 1200px) {
  .slider {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
    }
}

@media screen and (max-width: 1200px) {
  .slider {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 25%;
  height: 50vh;
    }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .slider {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20%;
  height: 50vh;
    }
}
</style>