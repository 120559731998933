<template>
<div>
    <Slider :items=sliderItems />
    <div class="calc-pay-div uk-flex uk-flex-row uk-visible@l">

      <div class="calc-block uk-flex uk-flex-row">
        <div class="calc-image uk-flex">
          <!-- <img src="/img/colc.svg" width="100" alt="lorem"> -->
         <i class="fas fa-calculator fa-3x main-page-icon"></i>
        </div>

        <div class="calc-description uk-flex uk-flex-column" @click=openCalc>
          <div class="calc-top uk-flex uk-flex-row">
            Калькулятор тарифів
          </div>
          <div class="calc-bottom uk-flex uk-flex-row">
            Виберіть самі потрібні вам послуги
          </div>
        </div>
      </div>
      
    <div class="pay-block uk-flex uk-flex-row" @click=openPay>
        <div class="pay-image uk-flex">
          <!-- <img src="/img/colc.svg" width="100" alt="lorem"> -->
         <i class="fas fa-coins fa-3x main-page-icon"></i>
        </div>
        <div class="pay-description uk-flex uk-flex-column">
          <div class="pay-top uk-flex uk-flex-row">
            Оплата через City24
          </div>
          <div class="pay-bottom uk-visible@s uk-flex uk-flex-row">
            Заплатіть за інтернет, не встаючи з дивана
          </div>
        </div>
    </div>
    </div>
    
    <div class="calc-pay-div uk-flex uk-flex-column uk-hidden@l">

      <div class="calc-block calc-mob-block uk-flex uk-flex-row">
        <div class="calc-image uk-flex">
          <!-- <img src="/img/colc.svg" width="100" alt="lorem"> -->
         <i class="fas fa-calculator fa-3x main-page-icon"></i>
        </div>

        <div class="calc-description uk-flex uk-flex-column" @click=openCalc>
          <div class="calc-top uk-flex uk-flex-row">
            Калькулятор тарифів
          </div>
          <div class="calc-bottom uk-visible@s uk-flex uk-flex-row">
            Виберіть самі потрібні вам послуги
          </div>
        </div>
      </div>
    <div class="pay-block pay-mob-block uk-flex uk-flex-row" @click=openPay>
        <div class="pay-image uk-flex">
          <!-- <img src="/img/colc.svg" width="100" alt="lorem"> -->
         <i class="fas fa-coins fa-3x main-page-icon"></i>
        </div>
        <div class="pay-description uk-flex uk-flex-column">
          <div class="pay-top uk-flex uk-flex-row">
            Оплата через City24
          </div>
          <div class="pay-bottom uk-visible@s uk-flex uk-flex-row">
            Заплатіть за інтернет, не встаючи з дивана
          </div>
        </div>
    </div>
    </div>

  






</div>
</template>

<script>
import Slider from "@/components/Slider.vue"

export default {
  name: "MainPage",
  components: {Slider},

  data() {
    return {
      sliderItems: [
        {
          title: "УВАГА! ЗМІНА ВАРТОСТІ ТАРИФІВ",
          subtitle: "<a href='/news'>(Перегляд інформації)</a>",
          img: "/img/i6.png",
        },
        {
          title: "Інтернет",
          subtitle: "<a href='/internet/house-panel/fiber'>Тарифи</a>",
          img: "/img/i1.png",
        },
        {
          title: "Телебачення",
          subtitle: "<a href='/tv/omega'>Тарифи</a>",
          img: "/img/i2.png",
        },
        {
          title: "Підключайся зараз!",
          subtitle: "<a href='/doc/blank.pdf'>Бланк замовлення</a><br><a href='/doc/dogovir-oferta-internet.pdf'>Договір Оферта (Інтернет)</a><br><a href='/doc/dogovir-oferta-tv1.pdf'>Договір Оферта (Телебачення)</a>",
          img: "/img/i3.png",
        },
        {
          title: "Надійність",
          subtitle: "<a href='/doc/operator-info.pdf'>Інформація про оператора</a>",
          img: "/img/i4.png",
        },
        {
          title: "Якість",
          subtitle: "<a href='/doc/zvit-fop.pdf'>Звіт ФОП</a>",
          img: "/img/i5.png",
        },
        {
          title: "Різноманіття",
          subtitle: "<a href='/doc/dogovir-oferta-internet-fop.pdf'>Договір Оферта ФОП</a>",
          img: "/img/i7.png",
        },
      ],
    }
  },
  methods: {
      openCalc()
      {   
          this.$router.push('/internet/calculator')
 
      },
      openPay()
      {   
          window.open("https://city24.ua/popolnit-internet/granat");
 
      },
  },



};
</script>

<style >


.calc-image, .pay-image{
  height: 100%;
  width: 20%;
  justify-content: center;
  align-items: center;
}

.calc-description, .pay-description{
  height: 100%;
  width: 80%;
  justify-content: center;
  align-items: center;
}

.main-page-icon{
  color: rgb(199,33,39);
}

.calc-logo, .pay-logo{
  height: 70% !important;
  margin-left: 30px;
  width: 80% !important;
}

.calc-mob-block{
  width: 100% !important;
  margin-bottom: 20px;
}

.pay-mob-block{
  width: 100% !important;
  margin-bottom: 20px;
}


.calc-image, .pay-image{
  color: rgb(200, 200, 200);
}

.calc-top, .pay-top{
  font-size: 20px;
  color: black;
  height: 40%;
  display: flex;
  align-items: center;
}

.calc-bottom, .pay-bottom{
  height: 60%;
  color: black;
  align-items: center;
}

.calc-block, .pay-block:hover{
  cursor: pointer;
}

.calc-pay-div{
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-between;
  margin-top: 40px;
}



@media screen and (min-width: 1200px) {
  .calc-block, .pay-block{
  height: 100%;
  width: 49%;
  background-color: white;
  border-radius: 10px;
}
}
@media screen and (max-width: 1200px) {
  .calc-block, .pay-block{
  height: 100%;
  width: 49%;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
}
}



</style>