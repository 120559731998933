import MainPage from "@/pages/MainPage.vue"
import InternetPage from "@/pages/InternetPage.vue"
import InternetHousePanel from "@/components/InternetHousePanel.vue"
import InternetFlatPanel from "@/components/InternetFlatPanel.vue"
import Calculator from "@/pages/internet/Calculator.vue"
import FlatFiber from "@/pages/internet/FlatFiber.vue"
import FlatDirect from "@/pages/internet/FlatDirect.vue"
import HouseFiber from "@/pages/internet/HouseFiber.vue"
import HouseRadiochannel from "@/pages/internet/HouseRadiochannel.vue"
import TVPage from "@/pages/TVPage.vue"
import Omega from "@/pages/tv/Omega.vue"
import Trinity from "@/pages/tv/Trinity.vue"
import NewsPage from "@/pages/NewsPage.vue"
import FullNews from "@/pages/FullNews.vue"
import BusinessInternetPage from "@/pages/BusinessInternetPage.vue"
import BusinessFiber from "@/components/BusinessFiber.vue"
import BusinessRadiochannel from "@/components/BusinessRadiochannel.vue"
import AdminNewsPage from "@/pages/Admin/AdminNewsPage.vue"
import AdminNewsItem from "@/pages/Admin/AdminNewsItem.vue"
import AdminTariffPage from "@/pages/Admin/AdminTariffPage.vue"
import AddNews from "@/pages/Admin/AddNews.vue"
import Login from "@/pages/Admin/Login.vue"
import AdminMenu from "@/pages/Admin/AdminMenu.vue"

export default [
 
    {
      path: '/',
      component: MainPage,
    },
    {
      path: '/admin/menu',
      component: AdminMenu,
    },
    {
      path: '/admin/news',
      component: AdminNewsPage,
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/admin/addnews',
      component: AddNews,
    },
    {
      path: '/admin/tariff',
      component: AdminTariffPage,
    },
    {
      path: '/admin/news/:id',
      component: AdminNewsItem,
    },
    {
      path: '/news',
      component: NewsPage,
    },
    {
      path: '/news/:id',
      component: FullNews,
    },
    {
      path: '/business',
      component: BusinessInternetPage,
      children: [
        {
          path: 'fiber',
          component: BusinessFiber
        },
        {
          path: 'radiochannel',
          component: BusinessRadiochannel
        },
        {
          path: '',
          redirect: 'fiber',
          component: BusinessFiber
        },
      ]
    },
    {
      path: '/internet',
      component: InternetPage,
      children: [
        {
          path: 'house-panel',
          component: InternetHousePanel,
          children: [
            {
              path: 'fiber',
              component: HouseFiber
            },
            {
              path: 'radiochannel',
              component: HouseRadiochannel
            },
            {
              path: '',
              redirect: 'fiber',
              component: HouseFiber
            },
          ]
        },
        {
          path: 'flat-panel',
          component: InternetFlatPanel,
          children: [
            {
              path: 'fiber',
              component: FlatFiber
            },
            {
              path: '',
              redirect: 'fiber',
              component: FlatFiber
            },
            {
              path: 'direct',
              component: FlatDirect
            },
          ]
        },
        {
          path: 'calculator',
          component: Calculator
        },
        {
          path: '',
          redirect: 'flat-panel',
          component: InternetFlatPanel
        },
      ]
    },
    {
      path: '/tv',
      component: TVPage,
      children: [
        {
          path: 'omega',
          component: Omega
        },
        {
          path: 'trinity',
          component: Trinity
        },
        {
          path: '',
          redirect: 'omega',
          component: Omega
        },
      ]
    },
    

]