import { render, staticRenderFns } from "./HouseFiber.vue?vue&type=template&id=3d66fcd8&scoped=true&"
import script from "./HouseFiber.vue?vue&type=script&lang=js&"
export * from "./HouseFiber.vue?vue&type=script&lang=js&"
import style0 from "./HouseFiber.vue?vue&type=style&index=0&id=3d66fcd8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d66fcd8",
  null
  
)

export default component.exports