<template>
<div class="main-block uk-flex uk-flex-column add-news-block">
    <div class="uk-flex uk-flex-row header-block">
        <div class="back-link" @click=moveBack><span uk-icon="icon: arrow-left; ratio: 1.5"></span><router-link class="" to="/admin/news">
        Назад
        </router-link> </div>
    <div class="admin-header"> Додання новини</div>
    </div>
    <div class="wrapper uk-flex uk-flex-column title-block">
    <div class="admin-item uk-flex uk-flex-column title">
        <div class="title-header uk-flex"> Заголовок:</div>
        <input class="input-item" type="text" v-model=title>
    </div>

    </div>
    <div class="wrapper uk-flex uk-flex-column teaser-block">
    <div class="admin-item uk-flex uk-flex-column teaser">
        <div class="teaser-header uk-flex"> Тизер: </div>
        <input class="input-item" type="text" v-model=teaser>
    </div>

    </div>
    <div class="wrapper uk-flex uk-flex-column content-block">
    <div class="admin-item uk-flex uk-flex-column content">
        <div class="content-header uk-flex"> Контент: </div>
        <textarea rows=5 v-model=content />
    </div>

    </div>
    <div class="wrapper uk-flex uk-flex-column img-block">
    <div class="admin-item uk-flex uk-flex-column img">
        <div class="img-header uk-flex"> Зображення: </div>
        <input class="input-item" type="file">
    </div>

    </div>
    
    <button @click=addNews class="approve"> Добавить </button>
</div>
</template>

<script>

import http from '@/http.js'

/* <input class="input-item" v-bind:class="{'uk-form-danger': !error.login}" type="text" v-model=login>
<div class="admin-item uk-flex uk-flex-row">
        <div> Заголовок:</div>
        <input class="input-item" type="text" v-model=login>
    </div>
    <span class="error-span" v-show="!error.login">Поле не повинно бути пустим</span>
*/

export default {
    name: "AddNews",
    data()
    {
        return {
            error: {
                title: true,
                teaser: true,
            },
            title: "",
            teaser: "",
            content: "",
        }
    },

    created()
    {
    },
    methods: {
       moveBack(){
            this.$router.push('/admin/news')
        },
        async addNews(){
            if(!this.title){
               this.error.title=false
               return
            }
            if(!this.teaser){
               this.error.teaser=false
               return
            }
            const input = this.$el.querySelector('input[type=file]'),
            file = input.files[0];
            try{
              if (file && file.type.match(/image.*/))
            {
            await http.post(`${process.env.VUE_APP_SERVER}/admin/news`,
                {title: this.title, teaser: this.teaser, content: this.content, img: file});
            
            this.$router.push({path: "/admin/news"})

            this.$notify(
            {
              group: 'foo',
              type: 'success',
              title: 'Вітаю!',
              text: `Новина успішно додана`
            });
            
            }
            else {
              await http.post(`${process.env.VUE_APP_SERVER}/admin/news`,
                {title: this.title, teaser: this.teaser, content: this.content});
            this.$notify(
            {
              group: 'foo',
              type: 'success',
              title: 'Вітаю!',
              text: `Новина успішно додана`
            });
            }
           
            }
            catch(err){
              this.$notify(
              {
                group: 'foo',
                type: 'error',
                title: 'Помилка, зверніться до адміністратора',
                text: `${err.jsonBody.humanText}`
              });
            }
        }, 
        
    }
}

</script>

<style scoped>

.add-news-block{
    align-items: center;
}

.title-block, .teaser-block, .content-block, .img-block{
    width: 25%;
}

.title, .content, .teaser, .img{
    justify-content: space-between;
}

.title-header, .teaser-header, .content-header, .img-header{
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.admin-header{
    font-size:30px;
}

.back-link{
    position: relative;
    left: -30%;
    display: flex;
    align-items: center;
}

.approve{
    margin-top: 20px;
}
</style>