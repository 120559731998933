<template>
<div class="uk-flex uk-flex-row news-page">
    <div class="news-block">
      <NewsRibbon :items=newsItems />
    </div>
    <div class="ads-block">
        <Ads />
    </div>

</div>
</template>

<script>
import NewsRibbon from "@/components/news/NewsRibbon.vue"
import Ads from "@/components/news/Ads.vue"

export default {
    name: "NewsPage",
    components: {NewsRibbon, Ads},
        
    data()
    {
        return {
            newsItems: [],
        }
    },

    async created()
    {
        const resp = await fetch(`${process.env.VUE_APP_SERVER}/api/news`)
        const data = await resp.json()
        this.newsItems = data.data
    }
    
}

</script>

<style scoped>

.news-page{
    justify-content: space-between;
}

.news-block{
    width: 80%;
    margin-left: 10%;
    
}

@media screen and (min-width: 1200px) { 
    .news-page{
     margin-top: 5%;    
  }
    .news-block{
    width: 80%;
    margin-left: 10%;
  
}
}

@media screen and (max-width: 1200px) { 
    .news-page{
     margin-top: 5%;    
  }
    .news-block{
    margin-top: 15%;
    width: 80%;
    margin-left: 10%;
}
}

@media screen and (max-width: 1200px) and (orientation: landscape) { 
    .news-page{
     margin-top: 5%;    
  }
    .news-block{
    margin-top: 10%;
    width: 80%;
    margin-left: 10%;
}
}

@media screen and (min-width: 1200px) and (orientation: landscape) { 
    .news-page{
     margin-top: 5%;    
  }
    .news-block{
    width: 80%;
    margin-left: 10%;
}
}

</style>