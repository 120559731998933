<template>
  <Multiselect style="width: calc( 100% - 50px )" class="TownSelectDiv" v-model="value" :options=$store.state.towns  :searchable=true placeholder="Населений пункт" :showLabels=false 
    :group-select=false group-label="group" group-values="values" open-direction="bottom"
    @input=onInput
  >
    <span slot="noResult">Нічого не знайдено</span>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {

  name: "TownSelect",
  
  components: {Multiselect},
  
  data() {
    return {
      value: undefined,
    }
  },
  
  created()
  {
    this.$store.dispatch("loadTowns")
  },
  
  methods: {
    onInput(value)
    {
      this.$emit("input", value)
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style>

.TownSelectDiv {
   max-width: -webkit-fill-available;
}

.TownSelectDiv:active {
    max-width: -webkit-fill-available;
    border-color: rgb(199,33,39);
}

.multiselect * {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 15px;
    touch-action: manipulation;
}

.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
}

.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease;
}

.multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 0px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
    border-style: none;
}

.multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
    color: #666;
}

.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 86%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
}

.multiselect__placeholder {
    color: #959595;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
    font-size: 15px;
}

.multiselect__element {
  color: #666;
}

</style>
