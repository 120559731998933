<template>
  <!--<b-modal centered title="HELIOS" ok-title="Войти"
           :no-close-on-backdrop=true :no-close-on-esc=true :ok-only=true :hide-header-close=true
           :visible=isLoginVisible @ok=onLogin @shown=onShown
           @keydown.native.enter=onLogin
  >
    <center>
    </center>

    <b-form class="modal-login__form">
      <b-form-group horizontal
                    :label-cols="3"
                    label="Логин"
      >
        <b-input name=login ref=login v-model=m.login :state="error" size="sm" />

      </b-form-group>
      
      <b-form-group horizontal
                    :label-cols="3"
                    label="Пароль"
      >
        <b-input name=password type=password v-model=m.password :state="error" size="sm" />
      </b-form-group>

      <b-alert v-if="error" variant="danger" show>
        <strong>Ошибка!</strong> {{ error }}
      </b-alert>
    </b-form>
  </b-modal> -->


  <div class="uk-flex uk-flex-column main-block" v-if="this.$store.state.token">
   <div class="news-block uk-flex uk-flex-column">
       <div>
           Новини
       </div>
       <div>Головна сторінка: <router-link to="/admin/news">Новини</router-link></div>
       <div>Додати новину: <router-link to="/admin/addnews">Додати новину</router-link></div>
   </div>
   <div class="tariff-block uk-flex uk-flex-column">
       <div>
           Тарифи
       </div>
       <div>Зміна тарифів: <router-link to="/admin/tariff">Змінити тарифи</router-link></div>
   </div>
    <button @click=logout>Вийти з аккаунту</button>
</div>

  
</template>

<script>
  export default {
    name: "AdminMenu",

    data: function () {
      return {
        
      }
    },

    methods: {
      logout(){
          this.$store.dispatch('clearToken')
          this.$router.push('/')
      },
    },

    computed: {
      
    },
  }
</script>

<style scoped>
.main-block{
  align-items: center;
}

.news-block, .admin-block, .tariff-block{
  width: 40%;
  align-items: center;
  margin-top: 20px;
}
</style>