<template>
<div class="uk-flex uk-flex-column internet-page">
    <div class="uk-flex uk-flex-row internet-menu">
        <div class="uk-flex uk-flex-row flat">
            <router-link class="router-link-internet-menu" to="/business/fiber">Оптичне підключення</router-link>
        </div>
    </div>

    <router-view />
    
</div>
</template>

<script>

export default {
  name: "Internet",

};
</script>

<style scoped>
.internet-menu{
    width: 100%;
    justify-content: center;
}

.internet-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 2%;
    align-items: center;

}


.router-link-active{
    color:rgb(199,33,39) !important;
}

.router-link-internet-menu{
    color: black;
}

.router-link-internet-menu:hover{
    color:rgb(199,33,39);
    outline: none;
    text-decoration: none;
}

.router-link-tarif-menu{
    color: black;
}

.router-link-tarif-menu:hover{
    color:rgb(199,33,39);
    outline: none;
    text-decoration: none;
}

.flat{
    font-size: 20px;
}

.house{
    margin-left: 5%;
    font-size: 20px;
}
.calc{
    margin-left: 5%;
    font-size: 20px;
}

@media screen and (max-width: 540px) { 
    .internet-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 15%;
    align-items: center;
}
}

@media screen and (max-width: 1200px) and (orientation: landscape) { 
    .internet-page{
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 15%;
    align-items: center;
}
}

</style>