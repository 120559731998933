<template>
<div class="uk-flex uk-flex-row qwe">
  <div class="news-block">
    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
        <div v-if="news.img" class="uk-card-media-left uk-cover-container icon-container">
            <img class="news-item-icon" :src=news.img alt="" uk-cover>
        </div>
            <div class="text-container"  :class="{'noimg-container': !news.img}">
        <div class="uk-card-body">
          <div class="uk-flex uk-flex-row">
            <div class="uk-card-title news-title">{{news.title}}</div>
            <div class="news-date uk-flex"><span class="uk-badge news-date-item">{{newsCreateTime}}</span></div>
          </div>
            <div class="news-teaser">{{news.teaser}}</div>
            <div v-if="news.content">
                <a class="link-more-news" :href="`/news/${news.id}`">Більше...</a>
            </div>
        </div>
    </div>
  </div>  
</div>
<div class="uk-flex change-delete-buttons">
    <button class="uk-button uk-button-primary edit-button" @click=editThis >Змінити</button>
    <button class="uk-button uk-button-danger del-button" @click=deleteThis >Видалити</button>
    </div>
</div>
</template>

<script scoped>

import moment from 'moment';

export default {
    name: "NewsItem", 

    props: {
        news: Object,
    },

    data()
    {      
        return {
   
        }
    },


    methods: {
      deleteThis()
      {
          this.$emit("delete", this.news.id)
      },
      editThis()
      {
          this.$router.push(`/admin/news/${this.news.id}`)
      }
    },

    computed: {
      newsCreateTime()
      {
        return moment.unix(this.news.create_date).format('DD.MM.YYYY')
      }
  },

    // еще тут могут быть разделы components, props, mounted, created, computed но пока они не нужны
}

</script>

<style scoped>

.news-block{
    width: 80%;
}
.change-delete-buttons{
    margin-top: 2em;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.news-item-icon{
    Object-fit: scale-down;
    width: 100% !important;
    height: 100% !important;
    padding: 10px;
}

.icon-container, .news-date{
    width: 25%;
}

.noimg-container{
    width: 100% !important;
}

.news-date{
    justify-content: flex-end;
}

.text-container, .news-title{
    width: 75%;
}

.link-more-news{
    color: rgb(199,33,39);
    text-decoration: none;
}

.edit-button, .del-button{
    height: 50%;
    margin-left: 10px;
}

.news-date-item{
    margin-bottom: 20px;
}
</style>