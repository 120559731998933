<template>
<div class="uk-flex uk-flex-row">
<label class="switch">
  <input type="checkbox" :checked="value ? 'checked' : null" @click=newValue />
  <span class="slider round"></span>
</label>
<div class="uk-flex service-name">{{title}}</div>
</div>
</template>

<script>

export default {
  name: "Switcher",
  props: {
      title: {
          type: String,
          default: '',
      },
      value: {
        type: Boolean,
        default: false,
      }, 
    },

  methods: {
      newValue(event)
      {
        this.$emit("input", event.target.checked)
      }
  }
}
</script>

<style scoped>

.service-name{
    align-items: center;
    margin-left: 15px;
    font-size: 18px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: rgb(199,33,39);
}



input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>