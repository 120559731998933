<template>
 
</template>

<script>

export default {
    name: "Ads",


    // данные компонента, делаются в виде функции, чтобы у каждой копии компонента был свой набор данных
    // иначе данные будут одни на всех
    data()
    {
        return {

        }
    },

    // еще тут могут быть разделы components, props, mounted, created, computed но пока они не нужны
}

</script>

<style scoped>
.main-ad{
    background-color: white;
}

</style>