import store from "@/store.js";

export function loginFromSession()
{
  const expires = sessionStorage.getItem("expires");
  const now = Math.floor(Date.now() / 1000);
  const name = sessionStorage.getItem("login");
  return expires && name && now < expires ? name : undefined;
}

export function logoutWhenExpired()
{
  const expires = sessionStorage.getItem("expires");
  const now = Math.floor(Date.now() / 1000);

  if (expires & now < expires)
  {
    setTimeout(function() { store.dispatch("logout") }, (expires-now)*1000);
  }
}