<template>
<div class="uk-flex uk-flex-row internet-house-radiochannel">
    <TaskModal ref="modal" /> 
    <div class="uk-flex uk-flex-column internet-house-radiochannel-tarif">
      <div class="internet-house-radiochannel-tarif-name">
        {{this.tariffs.e10.humanText}}
      </div>
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Домашній інтернет
          </div>
          <div>
            до {{this.tariffs.e10.speed}} Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: desktop; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Телебачення
          </div>
          <div>
            додатково
          </div>
        </div>
      </div>
       <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-house-radiochannel-tarif-price">
        <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-block-cost">
        <div class="uk-flex internet-house-radiochannel-tarif-cost">
          {{this.tariffs.e10.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-house-radiochannel-tarif-length">
          <div class="internet-house-radiochannel-tarif-currency">
            грн
          </div>
          <div class="internet-house-radiochannel-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-confirm">
         <button @click="chooseEpmp10" class="internet-house-radiochannel-tarif-button">Підключити</button>
      </div>
      </div>
    </div>
    <div class="uk-flex uk-flex-column internet-house-radiochannel-tarif">
      <div class="internet-house-radiochannel-tarif-name">
        {{this.tariffs.e20.humanText}}
      </div>
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Домашній інтернет
          </div>
          <div>
            до {{this.tariffs.e20.speed}} Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <!-- <i class="fas fa-check fa-2x"></i> -->
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: desktop; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Телебачення
          </div>
          <div>
            додатково
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-item">
        <span class="internet-house-radiochannel-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-house-radiochannel-tarif-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-house-radiochannel-tarif-price">
        <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-block-cost">
        <div class="uk-flex internet-house-radiochannel-tarif-cost">
          {{this.tariffs.e20.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-house-radiochannel-tarif-length">
          <div class="internet-house-radiochannel-tarif-currency">
            грн
          </div>
          <div class="internet-house-radiochannel-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-house-radiochannel-tarif-confirm">
        <button @click="chooseEpmp20" class="internet-house-radiochannel-tarif-button">Підключити</button>
      </div>
      </div>
    </div>
</div>
</template>

<script>

import store from '@/store'
import TaskModal from "@/components/TaskModal.vue"

export default {
  name: "HouseRadiochannel",

  components: {TaskModal}, 

  data(){
    return {
    }
  },
  
   methods: {
       chooseEpmp10()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.tariffs.e10.humanText} Цена ${this.tariffs.e10.price}`)
       },
       chooseEpmp20()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.tariffs.e20.humanText} Цена ${this.tariffs.e20.price}`)
       },
    },
    
    computed: {
      tariffs()
      {
        const i = this.$store.state.tariffs.internet
        return i ? i.private.house.radiochannel : {e10:{}, e20:{}}
      }
    },
};
</script>

<style>

  @media screen and (min-width: 1000px) {
    .internet-house-radiochannel-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 25%;
    }
    .internet-house-radiochannel{
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 20px;
    }
}
    @media screen and (max-width: 540px) {
    .internet-house-radiochannel{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .internet-house-radiochannel-tarif{
      border: 2px solid lightgrey;
      background-color:white;
            width: 80%;
    }
}

@media screen and ( min-width: 540px) and ( max-width: 1000px) {
    .internet-house-radiochannel{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .internet-house-radiochannel-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 50%;
    }
    
}


    

    .internet-house-radiochannel-tarif-price{
      justify-content: flex-end;
      height: 100%;
    }

    .internet-house-radiochannel-tarif-block-cost{
      justify-content: center;
    }

    .internet-house-radiochannel-tarif-name{
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 25px;
      text-align: center;
      font-weight: 600;
      color: black;
    }

    .internet-house-radiochannel-tarif-item{
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .internet-house-radiochannel-tarif-description{
      margin-left: 20px;
      letter-spacing: 1px;
    }

    .internet-house-radiochannel-tarif-item-icon{
      color: rgb(199,33,39);

    }

    .internet-house-radiochannel-tarif-cost{
      font-size: 50px;
      color: rgb(146,27,30);
    }

    .internet-house-radiochannel-tarif-length{
      justify-content: center;
      color: rgb(146,27,30);
      margin-left: 10px;
    }

    .internet-house-radiochannel-tarif-currency{
      margin-top: 5px;
      margin-bottom: -5px;
    }

    .internet-house-radiochannel-tarif-duration{
      margin-bottom: 5px;
    }

    .internet-house-radiochannel-tarif-confirm{
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
    }

    .internet-house-radiochannel-tarif-button{
      padding: 10px;
      width: 70%;
      border-radius: 5px;
      outline: none;
      border: 1px solid lightgrey;
    }

    .internet-house-radiochannel-tarif-button{
  -webkit-transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
}
.internet-house-radiochannel-tarif-button:hover {
  box-shadow: 0 0 40px 40px rgb(146,27,30) inset;
  border: 1px solid grey;
  color: white;
}
</style>