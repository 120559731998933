<template>
<div class="uk-flex uk-flex-row internet-business-radiochannel">
    <div class="uk-flex uk-flex-column internet-business-radiochannel-tarif">
      <div class="internet-business-radiochannel-tarif-name">
        КОРП-10
      </div>
      <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-business-radiochannel-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-business-radiochannel-tarif-description">
          <div>
           Корпоративний інтернет
          </div>
          <div>
            до 10 Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-item">
        <!-- <i class="fas fa-check fa-2x"></i> -->
        <span class="internet-business-radiochannel-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-business-radiochannel-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-business-radiochannel-tarif-price">
        <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-block-cost">
        <div class="uk-flex internet-business-radiochannel-tarif-cost">
          1100
        </div>
        <div class="uk-flex uk-flex-column internet-business-radiochannel-tarif-length">
          <div class="internet-business-radiochannel-tarif-currency">
            грн
          </div>
          <div class="internet-business-radiochannel-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-confirm">
        <button class="internet-business-radiochannel-tarif-button">Подключить</button>
      </div>
      </div>
    </div>
    <div class="uk-flex uk-flex-column internet-business-radiochannel-tarif">
      <div class="internet-business-radiochannel-tarif-name">
        КОРП-ІНД
      </div>
      <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-business-radiochannel-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-business-radiochannel-tarif-description">
          <div>
           Корпоративний інтернет
          </div>
          <div>
            індивідуально
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-item">
        <!-- <i class="fas fa-check fa-2x"></i> -->
        <span class="internet-business-radiochannel-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-business-radiochannel-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      
      <div class="uk-flex uk-flex-column internet-business-radiochannel-tarif-price">
        <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-block-cost">
        <div class="uk-flex internet-business-radiochannel-tarif-cost-individual">
          Індивідуально
        </div>
        </div>
        <div class="uk-flex uk-flex-row internet-business-radiochannel-tarif-confirm">
        <button class="internet-business-radiochannel-tarif-button">Подключить</button>
      </div>
      </div>
    </div>
</div>
</template>

<script>


export default {
  name: "BusinessRadiochannel",
};
</script>

<style>
    .internet-business-radiochannel{
        width: 100%;
        gap: 20px;
        justify-content: center;
        margin-top: 2em;
    }

    .internet-business-radiochannel-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 25%;
    }

    .internet-business-radiochannel-tarif-price{
      justify-content: flex-end;
      height: 100%;
    }

    .internet-business-radiochannel-tarif-block-cost{
      justify-content: center;
    }

    .internet-business-radiochannel-tarif-name{
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 25px;
      text-align: center;
      font-weight: 600;
      color: black;
    }

    .internet-business-radiochannel-tarif-item{
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .internet-business-radiochannel-tarif-description{
      margin-left: 20px;
      letter-spacing: 1px;
    }

    .internet-business-radiochannel-tarif-item-icon{
      color: rgb(199,33,39);

    }

    .internet-business-radiochannel-tarif-cost{
      font-size: 50px;
      color: rgb(146,27,30);
    }
    .internet-business-radiochannel-tarif-cost-individual{
      font-size: 40px;
      color: rgb(146,27,30);
    }

    .internet-business-radiochannel-tarif-length{
      justify-content: center;
      color: rgb(146,27,30);
      margin-left: 10px;
    }

    .internet-business-radiochannel-tarif-currency{
      margin-top: 5px;
      margin-bottom: -5px;
    }

    .internet-business-radiochannelinternet-business-radiochannel-tarif-duration{
      margin-bottom: 5px;
    }

    .internet-business-radiochannel-tarif-confirm{
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
    }

    .internet-business-radiochannel-tarif-button{
      padding: 10px;
      width: 70%;
      border-radius: 5px;
      outline: none;
      border: 1px solid lightgrey;
    }

    .internet-business-radiochannel-tarif-button{
  -webkit-transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
}
.internet-business-radiochannel-tarif-button:hover {
  box-shadow: 0 0 40px 40px rgb(146,27,30) inset;
  border: 1px solid grey;
  color: white;
}
</style>