import VueX from "vuex"
import Vue from "vue"
import http from "@/http.js"
import moment from 'moment';

Vue.use(VueX)

const state = {
    tariffs: {},
    towns: [],
    login: null,
    password: null,
    token: null,
    exp: null,
}

const mutations = {
  setTariffs(state, data) { state.tariffs = data },
  setTowns(state, data) { state.towns = data },
  
  setLogin(state, data) {
    state.login = data.login,
    state.password = data.password
  },
  
  setToken(state, data) {
    state.token = data.token,
    state.exp = data.exp
  },
}
 
const actions = {

    loadTariffs: async function(ctx)
    {
      const resp = await fetch(`${process.env.VUE_APP_SERVER}/api/meta/tariff`)
      const tariffs = await resp.json()
      ctx.commit("setTariffs", tariffs)
     },

    loadTowns: async function(ctx)
    {
      const resp = await fetch(`${process.env.VUE_APP_SERVER}/api/meta/towns`)
      const towns = await resp.json()
      ctx.commit("setTowns", towns)
     },

     renewToken: async function(ctx, {login,password}){
      ctx.commit("setLogin", {login, password})
      const formData  = new FormData();
      formData.append("login", login);
      formData.append("password", password);
      try
      {
      const resp = await fetch(`${process.env.VUE_APP_SERVER}/api/token`,
      {
        method: 'POST',
        body: formData,
      });
      if(resp.ok)
      {
        const {token, exp} = await resp.json()
        ctx.commit("setToken", {token, exp})
        return null
      }
      else
      {
        const ctype = resp.headers.get("Content-Type");
        if (ctype && (ctype.match(/^application\/json/) || ctype.match(/^application\/error\+json/)))
        {
        const status = await resp.json()
        return status.humanText
        }
        else
        {
          const error = await resp.text()
          return error
        }
      }
      } catch(error){
        return error
      }
     },
     getToken: async function(ctx){
      if(!ctx.state.login || !ctx.state.password){
        console.log("акция getToken: нет логина и пароля в хранилище")
        return null
      }
      const token = ctx.state.token
      const exp = ctx.state.exp
      const now = moment().unix()
      const renewTime = exp - 300
      if(!ctx.state.token || now > renewTime){
        const data = {login: ctx.state.login, password: ctx.state.password}
        await ctx.dispatch("login", data)
        return ctx.state.token
      }
      return token
      
     },
     clearToken: async function(ctx){
      ctx.commit("setLogin", {login: null, password: null})
      ctx.commit("setToken", {token: null, exp: null})
     }

} 

export default new VueX.Store({
    state,
    mutations,
    actions,
 })