<template>
  <!--<b-modal centered title="HELIOS" ok-title="Войти"
           :no-close-on-backdrop=true :no-close-on-esc=true :ok-only=true :hide-header-close=true
           :visible=isLoginVisible @ok=onLogin @shown=onShown
           @keydown.native.enter=onLogin
  >
    <center>
    </center>

    <b-form class="modal-login__form">
      <b-form-group horizontal
                    :label-cols="3"
                    label="Логин"
      >
        <b-input name=login ref=login v-model=m.login :state="error" size="sm" />

      </b-form-group>

      <b-form-group horizontal
                    :label-cols="3"
                    label="Пароль"
      >
        <b-input name=password type=password v-model=m.password :state="error" size="sm" />
      </b-form-group>

      <b-alert v-if="error" variant="danger" show>
        <strong>Ошибка!</strong> {{ error }}
      </b-alert>
    </b-form>
  </b-modal> -->


  <div>
   
    <input v-model=login type="text">
    <input v-model=password type="password">
    <button @click=adminLogin>Увійти</button>

</div>

  
</template>

<script>
  export default {
    name: "Login",

    data: function () {
      return {
        login: null,
        password: null,
      }
    },

    methods: {
      async adminLogin(){
          try{
          const response = await this.$store.dispatch('renewToken', {login: this.login, password: this.password})
          if (!response)
            {
              this.$notify(
              {
                group: 'foo',
                type: 'success',
                title: 'Вітаю',
                text: "Ви успішно залогінились",
              });
              this.$router.push('/admin/menu')
            }
            else{
                this.$notify(
              {
                group: 'foo',
                type: 'error',
                title: 'Помилка, зверніться до адміністратора',
                text: `${response.humanText}`,
              });
            }
          }
          catch(error){
              this.$notify(
              {
                group: 'foo',
                type: 'error',
                title: 'Помилка, зверніться до адміністратора',
                text: `${error}`,
              });
          }
      }
    },

    computed: {
    },
  }
</script>

<style scoped>
  .modal-login__logo {
    margin-bottom: 30px;
  }

  .modal-login__form {
    margin: 0 auto;
    max-width: 300px;
  }
</style>