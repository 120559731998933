<template>
  <div class="calc-disc uk-flex uk-flex-column">
  <div class="uk-flex uk-flex-row calculator-page">
    <div class="uk-flex uk-flex-column speed-services">
      <div class="house-flat-text header-item">
        Тип підключення
      </div>
      <div class="house-flat-chooser">
        <Chooser :items=houseChooserItems v-model=connectionType />
      </div>
      <div class="calc-speed-text header-item">
        Оберіть швидкість
      </div>
      <div class="speed-chooser"> <Chooser :items=calculatorSliderItems v-model=currentPrice /> </div>

      <div class="calc-services-text header-item">
        Телебачення
      </div>
      <div class="uk-flex uk-flex-column services">
        <div class="uk-flex uk-flex-column trinity-block">
          <div class="trinity-description uk-flex uk-fkex-row">
            <span uk-icon="icon: tv; ratio: 2"></span>
          <div class="trinity-text">
            Trinity TV
          </div>
          </div>
          <div class="trinity-chooser">
            <Chooser :items=trinityChooserItems v-model=currentTrinityPackage />
          </div>
        </div>

        <div class="uk-flex uk-flex-column omega-block">
          <div class="omega-description uk-flex uk-fkex-row">
            <span uk-icon="icon: tv; ratio: 2"></span>
          <div class="omega-text">
            Omega TV
          </div>
          <div id="omegaEfirAction">
          
          </div>
          </div>
          <div class="omega-chooser">
            <Chooser :items=omegaChooserItems v-model=currentOmegaPackage />
          </div>
        </div>
      </div>
    </div>

    <div class="uk-flex uk-flex-column services-price">
      <div class="uk-flex uk-flex-column router-services">

      <div class="calc-router-text header-item">
        Потрібен роутер?
      </div>
      
      <div class="uk-flex uk-flex-row router" :class="{withRouter}" @click=toggleRouter>
        <div class="uk-flex router-logo">
          <img class="router-image" data-src="/img/router.png" width="200" height="200" alt="" uk-img />
        </div>
        <div class="uk-flex uk-flex-column router-text">
          <div>
          Дозволяє користуватися Інтернет з усіх пристроїв одночасно, Без дротів (по Wi-Fi) або з ними, при наявності всього однієї лінії провайдера в квартирі.
          </div>
        </div>
        <div class="router-plus-minus">
          <PlusMinus v-model=withRouter plusIcon="minus" minusIcon="plus-circle" />
        </div>
      </div>

      <div class="calc-ip-text header-item">
        Додатково
      </div>

      <div class="uk-flex uk-flex-row static-ip-block">
          <Switcher title="Статична IP-адреса" v-model=withIp />
      </div>


      <form class="calc-form-block">

    <div class="uk-margin">
        <div class="uk-inline calc-form-item">
            <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: user"></span>
            <input v-model=fio class="uk-input calc-input" type="text" placeholder="Повне ім'я">
        </div>
    </div>

     <div class="uk-margin">
         <div class="uk-inline calc-form-item">
            <span  style ="z-index:100" class="uk-form-icon calc-form-icon-item" uk-icon="icon: location"></span>
            <TownSelect v-model=town class="uk-input calc-input" />
         </div>
     </div>

      <div class="uk-margin">
        <div class="uk-inline calc-form-item">
            <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: home"></span>
            <input v-model=location class="uk-input calc-input" type="text" placeholder="Ваша адреса">
        </div>
    </div>
    <div class="uk-margin">
        <div class="uk-inline calc-form-item">
            <span class="uk-form-icon calc-form-icon-item" uk-icon="icon: phone"></span>
            <input v-model=phone class="uk-input calc-input" type="text" placeholder="Ваш телефон">
        </div>
    </div>
  </form>

      
      
    </div>

    <div class="uk-flex uk-flex-row calc-pay-block">
      <div class="uk-flex pay-text header-item">
        Ваша абонплата:
      </div>
      <div class="uk-flex uk-flex-row calc-price-block">
        <div class="uk-flex calc-price">
          {{currentTariffPrice}}
        </div>
        <div class="uk-flex uk-flex-column calc-pay-period">
          <div>
            грн
          </div>
          <hr class="hr-period" />
          <div>
            міс
          </div>
        </div>
      
      </div>
        <div class="uk-flex uk-flex-row calc-tarif-confirm" style="justify-content: flex-end">
        <button @click=chooseCalc class="calc-pay-button">Підключити</button>
      </div>
    </div>
    </div>

    <!-- <Switcher title="Привет" v-model=defaultPosition />
    <Chooser :items=calculatorSliderItems v-model=defaultPrice />
    <PlusMinus v-model=withRouter /> -->
  </div>
  <div class="disc-block uk-flex uk-flex-column">
    <div class="disc-title">
      При поповненні на суму від 1000 грн нараховується бонус у розмірі 10% від суми внеску.
    </div>
  </div>
  </div>
</template>

<script>
import Switcher from "@/components/Switcher.vue";
import Chooser from "@/components/Chooser.vue";
import PlusMinus from "@/components/PlusMinus.vue";

import store from '@/store'
import {postTask} from "@/common.js"
import TownSelect from "@/components/TownSelect.vue"
import Multiselect from 'vue-multiselect'
export default {
  name: "Calculator",
  components: { Switcher, Chooser, PlusMinus, TownSelect },

  data() {
     return {
       task_attr:{},
      houseChooserItems: [
        {
          text: "Квартира",
          value: "flat"
        },
        {
          text: "Приватний сектор",
          value: "house"
        },
      ],
      withTrinity: false,
      withOmega: false,
      withIp: false,
      currentTrinityPackage: "notariff",
      currentOmegaPackage: "notariff",
      currentPrice: "p200",
      withRouter: false,
      connectionType: "flat",
      fio: '',
      phone: '',
      location: '',
      town: '',
    }
  },

 beforeRouteEnter: async (to, from, next) =>
 {
   if ('internet' in store.state.tariffs) return next()
   await store.dispatch("loadTariffs")
   next()
 },

 methods: {
      toggleRouter()
      {   
          this.withRouter = !this.withRouter
      },
      async chooseCalc(){
            const formData = {
              type: 'new-connection',
              title: 'Підключення інтернету',
              description: `Підключення інтернету: ${this.connectionType}
              пакет: ${this.currentPrice} Трініті: ${this.currentTrinityPackage} Омега: ${this.currentOmegaPackage} IP: ${this.withIp} роутер: ${this.withRouter}`,
              task_attr: {
                fio: this.fio,
                phone: this.phone,
                address: this.town + "," + "  " + this.location, 
              },
            }
            
            await postTask(formData)
        },
  },
  computed: {
      currentTariffPrice()
      {
        const t = this.$store.state.tariffs
        let sum = 0;
        sum += this.connectionType=="flat" ? +t.internet.private.flat.fiber[this.currentPrice].price : +t.internet.private.house.fiber[this.currentPrice].price;
            if (this.withRouter) sum += +this.$store.state.tariffs.additional.router.price;
            if (this.withIp) sum += +t.additional.ip.price;
          // Акция для омеги на 155 каналов не учитывать стоимость пакета Омега на скорости 200.
          const omegaNeeded = t.tv.OmegaTV[this.currentOmegaPackage] && !(this.currentPrice == "p200" && this.currentOmegaPackage == "efir")
          if (omegaNeeded) sum += +t.tv.OmegaTV[this.currentOmegaPackage].price
          sum += t.tv.TrinityTV[this.currentTrinityPackage] ? +t.tv.TrinityTV[this.currentTrinityPackage].price : 0
          return sum;
      },

      calculatorSliderItems()
      {
        const items = [
          {
            text: `<span style='font-weight:600'>${this.$store.state.tariffs.internet.private.flat.fiber.p200.speed}</span> мб/с`,
            value: "p200"
          }
        ]
        
        if (this.connectionType=="house")
        {
          items.unshift({
            text: `<span style='font-weight:600'>${this.$store.state.tariffs.internet.private.flat.fiber.p20.speed}</span> мб/с`,
            value: "p20"
          })
        }
        
        return items
      },
      trinityChooserItems()
      {
        return [{
          text: "<span uk-icon='icon: minus; ratio: 1'></span>",
          value: "notariff"
        },
        {
          text: `<span style='font-weight:600'>${this.$store.state.tariffs.tv.TrinityTV.minimal.channels}</span> каналів`,
          value: "minimal"
        },
        {
          text: `<span style='font-weight:600'>${this.$store.state.tariffs.tv.TrinityTV.medium.channels}</span> канали`,
          value: "medium"
        },
        {
          text: `<span style='font-weight:600'>${this.$store.state.tariffs.tv.TrinityTV.maximal.channels}</span> каналів`,
          value: "maximal"
        }
      ]
      },
      discountChooserItems()
      {
        return [{
          text:  `<span style='font-weight:600'>Щомісячна оплата</span>`,
          value: "0"
        },
        {
          text: `<span style='font-weight:600'>Передплата на 6 міс. -15%</span>`,
          value: "-15"
        },
        {
          text: `<span style='font-weight:600'>Передплата на 12 міс. -25%</span>`,
          value: "-25"
        },
        {
          text: `<span style='font-weight:600'>Передплата на 24 міс. -35%</span>`,
          value: "-35"
        }
      ]
      },
      omegaChooserItems()
      {
         return [{
          text: "<span uk-icon='icon: minus; ratio: 1'></span>",
          value: "notariff"
        },
        {
          text: `<span style='font-weight:600'>${this.$store.state.tariffs.tv.OmegaTV.efir.channels}</span> каналів`,
          value: "efir"
        },
        {
          text: `<span style='font-weight:600'>${this.$store.state.tariffs.tv.OmegaTV.premium.channels}</span> каналів`,
          value: "premium"
        },
        {
          text: `<span style='font-weight:600'>${this.$store.state.tariffs.tv.OmegaTV.vip.channels}</span> каналів`,
          value: "vip"
        }
      ]
      },
  },
  
  watch: {
    connectionType: {
      immediate: true,
      handler(val)
      {
        if (val=="flat") this.currentPrice = "p200"
      },
    },
  },
};
</script>

<style>

@media screen and (min-width: 1200px) {
  .calculator-page {
  border-radius: 25px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  background-color: white;
}
.speed-services{
  height:100%;
  width: 50%;
}.services-price{
  height: 100%;
  width: 50%;
}
.calc-pay-block{
  height: 20%;
  margin-left: 5%;
  margin-right: 10%;
}
.calc-pay-button{
      padding: 10px;
      width: 80%;
      border-radius: 5px;
      outline: none;
      border: 2px solid lightgrey;
      font-weight: 400;
      height: 30%;
    }
}

@media screen and ( max-width: 1200px){
  .calculator-page {
  border-radius: 25px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  flex-direction: column;
  background-color: white;
}
.speed-services{
  height:100%;
  width: 100%;
}
.calc-pay-block{
  margin-left: 5%;
  margin-right: 10%;
  flex-direction: column;
  align-items: center;
}

    .calc-pay-button{
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      outline: none;
      border: 2px solid lightgrey;
      font-weight: 400;
      height: 50px;
      margin-bottom: 20px;
    }
}








.services{
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 5%;
  height: 92%;
}

.calc-speed-text{
  font-size: 24px;
  margin-top: 3.5em;
  height: 8%;
  margin-left: 10%;
  margin-right: 5%;
}

.calc-services-text{
  font-size: 24px;
  margin-top: 3.5em;
  margin-left: 10%;
  height: 8%;
  margin-right: 5%;
}
.house-flat-text{
  font-size: 24px;
  margin-top: 15px;
  margin-left: 10%;
  height: 8%;
  margin-right: 5%;
}

.speed-chooser{
  height: 10%;
  margin-left: 10%;
  margin-top: 15px;
  margin-right: 5%;
}

.router{
  background-color: white;
  height: 35%;
  margin-right: 10%;
  margin-left: 5%;
  border-radius: 10px;
  padding: 1em; 
}

.router-image {
  opacity: 0.25;
}

.disc-block{
  background-color: white;
  margin-top: 20px;
  border-radius: 25px;
  padding: 25px;
}

.disc-chooser{
  margin-top: 20px;
}

.disc-title{
  font-size: 24px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.router-logo{
  height: 100%;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.withRouter .router-image{
  opacity: 1; 
}

.withRouter{
  border: 1px solid rgb(199,33,39);
  background-color: rgb(239,239,239);
}


.router-image{
  height: auto;
  width: auto;
}

.router-text{
  height: 100%;
  width: 60%;
  padding-left: 15px;
}

.router-plus-minus{
  display: flex;
  justify-content: center;
  height: 100%;
  width: 15%;
  margin-top: 10px;
}

.trinity-description{
  font-size: 20px;
  margin-bottom: 10px;
  height: 50%;
  align-items: center;
}
.omega-description{
  font-size: 20px;
  margin-bottom: 10px;
  height: 50%;
  align-items: center;
}

.trinity-text{
  margin-left: 20px;
}
.omega-text{
  margin-left: 20px;
}

.trinity-block{
  height: 33%;
}
.omega-block{
  height: 33%;
  margin-top: 25px;
}

.omega-chooser{
  height: 50%;
}
.trinity-chooser{
  height: 50%;
}

.static-ip-block{
  margin-top: 15px;
  margin-left: 5%;
}


.calc-router-text{
  font-size: 24px;
  margin-top: 15px;
  height: 8%;
  margin-left: 5%;
  margin-right: 10%;
  margin-bottom: 10px;
}

.calc-ip-text{
  font-size: 24px;
  margin-top: 25px;
  height: 8%;
  margin-left: 5%;
  margin-right: 10%;
}

.router-services{
  height: 80%;
}

.house-flat-chooser{
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 15px;
}

.calc-input:focus{
  border-color: rgb(199,33,39);
}
.calc-input{
  border-radius: 10px;
}

.calc-form-block{
  margin-left: 5%;
  margin-top: 3.5em;
  margin-right: 10%;
}

.calc-form-item{
  width: 100%;
}



.pay-text{
  align-items: center;
  font-size: 20px;
  width: 30%;
}

.calc-price-block{
  width: 30%;
  margin-right: 10px;
}

.calc-price{
  height: 100%;
  font-size: 70px;
  align-items: center;
  color: rgb(199,33,39);
  margin-right: 10px;
}

.hr-period{
  margin: 0;
}
.calc-pay-period{
  justify-content: center;
}



.calc-pay-button{
  -webkit-transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
}

.calc-pay-button:hover{
  box-shadow: 0 0 40px 40px rgb(146,27,30) inset;
  border: 1px solid grey;
  color: white;
}

.calc-tarif-confirm{
      width: 35%;
      justify-content: center;
      align-items: center;
    }

.header-item{
  font-weight: 500;
}

.calc-form-icon-item{
  color: rgb(199,33,39);
}

.TownSelectDiv {
    max-width: -webkit-fill-available;
}

.TownSelectDiv:active {
    max-width: -webkit-fill-available;
    border-color: rgb(199,33,39);
}

.uk-input:not(input), .uk-select:not(select) {
    line-height: 20px;
}

.multiselect * {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 15px;
    touch-action: manipulation;
}

.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
}

.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease;
}

.multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 0px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
    border-style: none;
    background-color: transparent;
}

.multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
    color: #666;
}

.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 86%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
}

.multiselect__placeholder {
    color: #959595;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
    font-size: 15px;
}

.multiselect__element {
  color: #666;
}
</style>
