<template>
<div class="uk-flex uk-flex-column flat-panel">
    <div class="uk-flex uk-flex-row flat-menu">
        <div class="uk-flex uk-flex-row flat-fiber"><router-link class="router-link-tarif-menu" to="/internet/flat-panel/fiber">Оптичне підключення</router-link></div>
    </div>

    <router-view />
    
</div>
</template>

<script>


export default {
  name: "InternetFlatPanel",

};
</script>

<style >

.flat-menu{
    justify-content: center;
    margin-bottom: 15px;
    
}

.flat-direct, .flat-fiber{
    font-size: 18px;
}

.flat-fiber{
    margin-right: 0%;
}

.flat-panel{
    margin-top: 10px;
    width: 100%;
}

</style>