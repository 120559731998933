g<template>
<div class="internet-business-fiber">
<TaskModal ref="modal" />
    <div class="uk-flex uk-flex-column internet-business-fiber-tarif">
      <div class="internet-business-fiber-tarif-name">
          {{$store.state.tariffs.internet.business.fiber.corp20.humanText}}
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-fiber-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-business-fiber-tarif-description">
          <div>
           Корпоративний інтернет
          </div>
          <div>
            до {{$store.state.tariffs.internet.business.fiber.corp20.speed}} Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-fiber-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-business-fiber-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-business-tarif-item-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-business-fiber-tarif-price">
        <div class="uk-flex uk-flex-row internet-business-fiber-tarif-block-cost">
        <div class="uk-flex internet-business-fiber-tarif-cost-individual">
         {{$store.state.tariffs.internet.business.fiber.corp20.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-business-fiber-tarif-length">
          <div class="internet-house-fiber-tarif-currency">
            грн
          </div>
          <div class="internet-house-fiber-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-business-fiber-tarif-confirm">
         <button @click="chooseBusiness20" class="internet-business-fiber-tarif-button">Підключити</button>
      </div>
      </div>
    </div>

    <div class="uk-flex uk-flex-column internet-business-fiber-tarif">
      <div class="internet-business-fiber-tarif-name">
        КОРП-100
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-fiber-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-business-fiber-tarif-description">
          <div>
           Корпоративний інтернет
          </div>
          <div>
            до {{$store.state.tariffs.internet.business.fiber.corp100.speed}} Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-fiber-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-business-fiber-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-business-tarif-item-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>
      
      <div class="uk-flex uk-flex-column internet-business-fiber-tarif-price">
        <div class="uk-flex uk-flex-row internet-business-fiber-tarif-block-cost">
        <div class="uk-flex internet-business-fiber-tarif-cost-individual">
          {{$store.state.tariffs.internet.business.fiber.corp100.price}}
        </div>
        </div>
        <div class="uk-flex uk-flex-row internet-business-fiber-tarif-confirm">
       <button @click="chooseBusiness100" class="internet-business-fiber-tarif-button">Підключити</button>
      </div>
      </div>
    </div>

    <div class="uk-flex uk-flex-column internet-business-fiber-tarif">
      <div class="internet-business-fiber-tarif-name">
        КОРП-1000
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-fiber-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-business-fiber-tarif-description">
          <div>
           Корпоративний інтернет
          </div>
          <div>
            до {{$store.state.tariffs.internet.business.fiber.corp1000.speed}} Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-fiber-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-business-fiber-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-business-fiber-tarif-item">
        <span class="internet-business-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-business-tarif-item-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-column internet-business-fiber-tarif-price">
        <div class="uk-flex uk-flex-row internet-business-fiber-tarif-block-cost">
        <div class="uk-flex internet-business-fiber-tarif-cost-individual">
          {{$store.state.tariffs.internet.business.fiber.corp1000.price}}
        </div>
        </div>
        <div class="uk-flex uk-flex-row internet-business-fiber-tarif-confirm">
       <button @click="chooseBusiness1000" class="internet-business-fiber-tarif-button">Підключити</button>
      </div>
     </div> 
   </div>
 </div>
  
 

</template>

<script>
import store from '@/store'
import TaskModal from "@/components/TaskModal.vue" 

export default {
  name: "BusinessFiber",
  components: {TaskModal}, 
  data(){
    return {
      
    }
  },
  
  beforeRouteEnter: async (to, from, next) =>
 {
   if ('internet' in store.state.tariffs) return next()
   await store.dispatch("loadTariffs")
   next()
 },
 methods: {
       chooseBusiness20()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.$store.state.tariffs.internet.business.fiber.corp20.humanText} Цена ${this.$store.state.tariffs.internet.business.fiber.corp20.humanText}`)
       },
       chooseBusiness100()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.$store.state.tariffs.internet.business.fiber.corp100.humanText} Цена ${this.$store.state.tariffs.internet.business.fiber.corp100.humanText}`)
       },
       chooseBusiness1000()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.$store.state.tariffs.internet.business.fiber.corp1000.humanText} Цена ${this.$store.state.tariffs.internet.business.fiber.corp1000.humanText}`)
       },
    },
    computed: {
      tariffs()
      {
        return this.$store.state.tariffs.internet.business
      }
    },
};
</script>

<style>
 @media screen and (min-width: 1000px){
    .internet-business-fiber{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
    .internet-business-fiber-tarif{
       border: 2px solid lightgrey;
      background-color:white;
      width: 80%;
    }
 }
@media screen and (max-width: 540px) {
    .internet-business-fiber{
        display: flex;
        flex-direction: column;
        align-item: center;
        gap: 20px;
    }
     .internet-business-fiber-tarif{
         border: 2px solid lightgrey;
         background-color:white;
         width: 100%;
    }
}
 @media screen and ( min-width: 540px) and ( max-width: 1000px) {
     .internet-business-fiber{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .internet-business-fiber-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 80%;
    }
}    
 
   .internet-business-fiber-tarif-item{
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .internet-business-fiber-tarif-description{
      margin-left: 20px;
      letter-spacing: 1px;
    }

  .internet-business-tarif-item-icon{
      color: rgb(199,33,39);
    }

    .internet-business-fiber-tarif-price{
      justify-content: flex-end;
      height: 100%;
    }

    .internet-business-fiber-tarif-block-cost{
      justify-content: center;
    }

    .internet-business-fiber-tarif-name{
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 25px;
      text-align: center;
      font-weight: 600;
      color: black;
    }

    .internet-business-fiber-tarif-item{
      margin-left: 15px;
      margin-bottom: 10px;
      margin-right: 15px;
    }

    .internet-business-tarif-item-description{
      margin-left: 20px;
      letter-spacing: 1px;
    }

    .internet-business-fiber-tarif-item-icon{
      color: rgb(199,33,39);

    }

    .internet-business-fiber-tarif-cost{
      font-size: 50px;
      color: rgb(146,27,30);
    }
    .internet-business-fiber-tarif-cost-individual{
      font-size: 30px;
      color: rgb(146,27,30);
    }

    .internet-business-fiber-tarif-length{
      justify-content: center;
      color: rgb(146,27,30);
      margin-left: 10px;
    }

    .internet-business-fiber-tarif-currency{
      margin-top: 5px;
      margin-bottom: -5px;
    }

    .internet-business-fiber-tarif-duration{
      margin-bottom: 5px;
    }

    .internet-business-fiber-tarif-confirm{
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
    }

    .internet-business-fiber-tarif-button{
      padding: 10px;
      width: 70%;
      border-radius: 5px;
      outline: none;
      border: 1px solid lightgrey;
    }

    .internet-business-fiber-tarif-button{
  -webkit-transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
}
.internet-business-fiber-tarif-button:hover {
  box-shadow: 0 0 40px 40px rgb(146,27,30) inset;
  border: 1px solid grey;
  color: white;
}
</style>