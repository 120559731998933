<template>
<div class="uk-flex uk-flex-column house-panel">
    <div class="uk-flex uk-flex-row house-menu">
        <div class="uk-flex uk-flex-row house-fiber"><router-link class="router-link-tarif-menu" to="/internet/house-panel/fiber">Оптичне підключення</router-link></div>
        <div class="uk-flex uk-flex-row house-radiochannel"><router-link class="router-link-tarif-menu" to="/internet/house-panel/radiochannel">Радіоканал</router-link></div>
    </div>

    <router-view />
    
</div>
</template>

<script>


export default {
  name: "InternetHousePanel",

};
</script>

<style >

.house-panel{
    margin-top: 10px;
    width: 100%;
}

.house-menu{
    justify-content: center;
    margin-bottom: 15px;
}

.house-fiber, .house-radiochannel{
    font-size: 18px;
}

.house-fiber{
    margin-right: 5%;
}

.house-radiochannel{
    margin-right: 5%;
}


</style>