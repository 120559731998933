var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Slider',{attrs:{"items":_vm.sliderItems}}),_c('div',{staticClass:"calc-pay-div uk-flex uk-flex-row uk-visible@l"},[_c('div',{staticClass:"calc-block uk-flex uk-flex-row"},[_vm._m(0),_c('div',{staticClass:"calc-description uk-flex uk-flex-column",on:{"click":_vm.openCalc}},[_c('div',{staticClass:"calc-top uk-flex uk-flex-row"},[_vm._v(" Калькулятор тарифів ")]),_c('div',{staticClass:"calc-bottom uk-flex uk-flex-row"},[_vm._v(" Виберіть самі потрібні вам послуги ")])])]),_c('div',{staticClass:"pay-block uk-flex uk-flex-row",on:{"click":_vm.openPay}},[_vm._m(1),_vm._m(2)])]),_c('div',{staticClass:"calc-pay-div uk-flex uk-flex-column uk-hidden@l"},[_c('div',{staticClass:"calc-block calc-mob-block uk-flex uk-flex-row"},[_vm._m(3),_c('div',{staticClass:"calc-description uk-flex uk-flex-column",on:{"click":_vm.openCalc}},[_c('div',{staticClass:"calc-top uk-flex uk-flex-row"},[_vm._v(" Калькулятор тарифів ")]),_c('div',{staticClass:"calc-bottom uk-visible@s uk-flex uk-flex-row"},[_vm._v(" Виберіть самі потрібні вам послуги ")])])]),_c('div',{staticClass:"pay-block pay-mob-block uk-flex uk-flex-row",on:{"click":_vm.openPay}},[_vm._m(4),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calc-image uk-flex"},[_c('i',{staticClass:"fas fa-calculator fa-3x main-page-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-image uk-flex"},[_c('i',{staticClass:"fas fa-coins fa-3x main-page-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-description uk-flex uk-flex-column"},[_c('div',{staticClass:"pay-top uk-flex uk-flex-row"},[_vm._v(" Оплата через City24 ")]),_c('div',{staticClass:"pay-bottom uk-visible@s uk-flex uk-flex-row"},[_vm._v(" Заплатіть за інтернет, не встаючи з дивана ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calc-image uk-flex"},[_c('i',{staticClass:"fas fa-calculator fa-3x main-page-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-image uk-flex"},[_c('i',{staticClass:"fas fa-coins fa-3x main-page-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-description uk-flex uk-flex-column"},[_c('div',{staticClass:"pay-top uk-flex uk-flex-row"},[_vm._v(" Оплата через City24 ")]),_c('div',{staticClass:"pay-bottom uk-visible@s uk-flex uk-flex-row"},[_vm._v(" Заплатіть за інтернет, не встаючи з дивана ")])])
}]

export { render, staticRenderFns }