g<template>
<div class="internet-flat-direct">
    
    <TaskModal ref="modal" />
    
    <div class="uk-flex uk-flex-column internet-flat-direct-tarif">
      <div class="internet-flat-direct-tarif-name">
        {{$store.state.tariffs.internet.private.flat.direct.f20.humanText}}<br>(архівний)
      </div>
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Домашній інтернет
          </div>
          <div>
            до {{$store.state.tariffs.internet.private.flat.direct.f20.speed}} Мбіт/с
          </div>
        </div>
      </div>
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-check fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tv fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: desktop; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Телебачення
          </div>
          <div>
            кількість каналів {{$store.state.tariffs.internet.private.flat.direct.f20.channels}}<br>(включено в тариф)
          </div>
        </div>
      </div>

      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>

      <div class="uk-flex uk-flex-column internet-flat-direct-tarif-price">
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-block-cost">
        <div class="uk-flex internet-flat-direct-tarif-cost">
          {{$store.state.tariffs.internet.private.flat.direct.f20.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-flat-direct-tarif-length">
          <div class="internet-flat-direct-tarif-currency">
            грн
          </div>
          <div class="internet-flat-direct-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-confirm">
        <button @click="chooseF20" class="internet-flat-direct-tarif-button">Підключити</button>
      </div>
      </div>
    </div>

    <div class="uk-flex uk-flex-column internet-flat-direct-tarif">
      <div class="internet-flat-direct-tarif-name">
        {{$store.state.tariffs.internet.private.flat.direct.f100.humanText}}<br>(архівний)
      </div>
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tachometer-alt fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: cloud-download; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Домашній інтернет
          </div>
          <div>
            до {{$store.state.tariffs.internet.private.flat.direct.f100.speed}} Мбіт/с
          </div>
        </div>
      </div>
    
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-check fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: check; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Трафік
          </div>
          <div>
            без обмежень
          </div>
        </div>
      </div>
      
      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tv fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: desktop; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Телебачення
          </div>
          <div>
            кількість каналів {{$store.state.tariffs.internet.private.flat.direct.f100.channels}}<br>(включено в тариф)
          </div>
        </div>
      </div>

      <div class="uk-flex uk-flex-row internet-flat-direct-tarif-item">
        <!-- <i class="fas fa-tv fa-2x"></i> -->
        <span class="internet-flat-direct-tarif-item-icon" uk-icon="icon: home; ratio: 1.5"></span>
        <div class="internet-flat-direct-tarif-description">
          <div>
           Статична адреса
          </div>
          <div>
            +60 грн/міс (до вартості тарифу)
          </div>
        </div>
      </div>

      <div class="uk-flex uk-flex-column internet-flat-direct-tarif-price">
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-block-cost">
        <div class="uk-flex internet-flat-direct-tarif-cost">
          {{$store.state.tariffs.internet.private.flat.direct.f100.price}}
        </div>
        <div class="uk-flex uk-flex-column internet-flat-direct-tarif-length">
          <div class="internet-flat-direct-tarif-currency">
            грн
          </div>
          <div class="internet-flat-direct-tarif-duration">
            міс
          </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-row internet-flat-direct-tarif-confirm">
        <button @click="chooseF100" class="internet-flat-direct-tarif-button">Підключити</button>

      </div>
      </div>
    </div>

</div>
</template>

<script>

import store from '@/store'
import TaskModal from "@/components/TaskModal.vue"

export default {
  name: "FlatDirect",
  components: {TaskModal},
  
  data(){
    return {
    }
  },
  
  beforeRouteEnter: async (to, from, next) =>
 {
   if ('internet' in store.state.tariffs) return next()
   await store.dispatch("loadTariffs")
   next()
 },

  methods: {
       chooseF100()
       {
          this.$refs.modal.$emit("open", `Тариф: ${this.tariffs.flat.direct.f100.humanText} Цена ${this.tariffs.flat.direct.f100.price}`)
       },

       chooseF20()
       {
         this.$refs.modal.$emit("open", `Тариф: ${this.tariffs.flat.direct.f20.humanText} Цена ${this.tariffs.flat.direct.f20.price}`)
       },
    },
    
    computed: {
      tariffs()
      {
        return this.$store.state.tariffs.internet.private
      }
    },

};
</script>

<style scoped>
    

    @media screen and (min-width: 1000px) {
      .internet-flat-direct{
        display: flex;
        flex-direction: row;
        justify-content:center;
        gap: 20px;
    }
    .internet-flat-direct-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 25%;
    }
}
    @media screen and (max-width: 540px) {
      .internet-flat-direct{
        display: flex;
        flex-direction: column;
      gap: 20px;
      align-items: center;
    }
    .internet-flat-direct-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 80%;
    }
}
    @media screen and ( min-width: 540px) and ( max-width: 1000px) {
      .internet-flat-direct{
        display: flex;
        flex-direction: column;
      gap: 20px;
      align-items: center;
    }
    .internet-flat-direct-tarif{
      border: 2px solid lightgrey;
      background-color:white;
      width: 50%;
    }

    
}

    

    .internet-flat-direct-tarif-price{
      justify-content: flex-end;
      height: 100%;
    }

    .internet-flat-direct-tarif-block-cost{
      justify-content: center;
    }

    .internet-flat-direct-tarif-name{
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 25px;
      text-align: center;
      font-weight: 600;
      color: black;
    }

    .internet-flat-direct-tarif-item{
      margin-left: 15px;
      margin-bottom: 10px;
    }

    .internet-flat-direct-tarif-description{
      margin-left: 20px;
      letter-spacing: 1px;
    }

    .internet-flat-direct-tarif-item-icon{
      color: rgb(199,33,39);

    }

    .internet-flat-direct-tarif-cost{
      font-size: 50px;
      color: rgb(146,27,30);
    }

    .internet-flat-direct-tarif-length{
      justify-content: center;
      color: rgb(146,27,30);
      margin-left: 10px;
    }

    .internet-flat-direct-tarif-currency{
      margin-top: 5px;
      margin-bottom: -5px;
    }

    .internet-flat-direct-tarif-duration{
      margin-bottom: 5px;
    }

    .internet-flat-direct-tarif-confirm{
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
    }

    .internet-flat-direct-tarif-button{
      padding: 10px;
      width: 70%;
      border-radius: 5px;
      outline: none;
      border: 1px solid lightgrey;
    }

    .internet-flat-direct-tarif-button{
  -webkit-transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 500ms ease-in-out, color 300ms ease-in-out;
}
.internet-flat-direct-tarif-button:hover {
  box-shadow: 0 0 40px 40px rgb(146,27,30) inset;
  border: 1px solid grey;
  color: white;
}
</style>